import React from 'react';
import styled from '@emotion/styled';
import {Divider, TextField, Chip} from '@mui/material';
import Autocomplete, {AutocompleteChangeReason} from '@mui/material/Autocomplete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {emailValidationSchema} from 'src/utils/validations';
import {UserAddress, UserAddressType} from 'src/types';
import Button from '@mui/material/Button';
import formatNationalNumber from 'src/utils/locating/formatNationalNumber';
import client from 'src/apollo';
import store from 'src/redux';
import {InviteUserByAddresses} from 'src/gql/mutation/InviteUserToOrganizationMutation';
import {toast} from 'react-toastify';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {DetailedToast} from './CustomToasts';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 0;
`;

const FlexContainer = styled.div`
  display: flex;
  margin-top: 1.5em;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.greyishBrown};
  button {
    margin-left: 1em;
    text-transform: capitalize !important;
    width: fit-content;
    height: 40px;
    &:first-of-type {
      color: ${(props) => props.theme.colors.chatTeal};
    }
  }
`;

const AutoCompleteWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
  margin-top: 1em;
  padding-top: 10px;
  .MuiInputBase-root {
    align-items: flex-start !important;
    min-height: 6em !important;
  }
  .MuiAutocomplete-endAdornment {
    display: none !important;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    min-width: 100px !important;
  }
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 9px !important;
  }
`;

const options: UserAddress[] = [];

const defaultUserAddressParameters: UserAddress = {
  address: '',
  type: 'email',
  access: 'public',
  ordinal: 'primary',
};

interface Props {
  closeModal?: () => void;
  onComplete?: () => void;
}

// TODO: separate all concerns into different hooks and views

const AddressAutoCompleteManager = ({closeModal, onComplete}: Props) => {
  const [selectedList, setSelectedList] = React.useState<UserAddress[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const inputOnChangeRef = React.useRef('');
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const [currentInputValid, setCurrentInputValid] = React.useState(0);

  const focusedAutoCompleteInput = () => {
    document.getElementById('inviteColleagueAutoComplete')?.focus();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (!inputOnChangeRef.current || !inputOnChangeRef.current.trim()) return;
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      handleUpdateSelectedList(true).catch(() => null);
    }
  };

  const handleUpdateSelectedList = (shouldAddInList: Boolean): Promise<UserAddressType> => {
    if (errorMessage) setErrorMessage('');
    const isDuplicationExist = selectedList.find((address) => {
      return address.address === inputOnChangeRef.current;
    });
    return new Promise((resolve, reject) => {
      if (Boolean(isDuplicationExist)) {
        setErrorMessage('This address has already been selected');
        setCurrentInputValid(0);
        return reject('This address has already been selected');
      }

      let trimmedInput = inputOnChangeRef.current.trim().replace(/\s/g, '');

      return emailValidationSchema
        .validate(trimmedInput)
        .then(() => {
          shouldAddInList &&
            setSelectedList((prevSelected) =>
              prevSelected.concat({
                ...defaultUserAddressParameters,
                address: trimmedInput,
              }),
            );
          resolve('email');
          setCurrentInputValid(shouldAddInList ? 0 : 1);
        })
        .catch((err) => {
          setErrorMessage(err.errors[0]);
          reject(err.errors[0]);
          setCurrentInputValid(0);
        });
    });
  };

  const handleOnChange = (event: React.ChangeEvent<{}>, newValue: UserAddress[], reason: AutocompleteChangeReason) => {
    if (reason === 'removeOption') {
      setSelectedList(newValue);
    }
  };

  const onInputChange = async (e: React.ChangeEvent<{}>, value: string) => {
    if (Boolean(value) && errorMessage && value.slice(-1) !== ' ' && value.slice(-1) !== ',') {
      setErrorMessage('');
    }
    inputOnChangeRef.current = value;
    value === '' && setErrorMessage('');
    const hasSelectedAddresses = selectedList.length > 0;
    if (!inputOnChangeRef.current && !hasSelectedAddresses) {
      setErrorMessage('Please enter a email');
      focusedAutoCompleteInput();
      return;
    }
    if (!inputOnChangeRef.current || !inputOnChangeRef.current.trim()) return;
    await handleUpdateSelectedList(false).catch(() => null);
  };

  const handleSubmit = async () => {
    const hasSelectedAddresses = selectedList.length > 0;
    if (errorMessage) setErrorMessage('');
    if (!inputOnChangeRef.current && !hasSelectedAddresses) {
      setErrorMessage('Please enter a email');
      focusedAutoCompleteInput();
      return;
    }

    try {
      let type: UserAddressType;
      let addresses: UserAddress[] = selectedList;
      let lastAddress = inputOnChangeRef.current.trim().replace(/\s/g, '');

      if (lastAddress !== '') {
        type = await handleUpdateSelectedList(true);
        addresses = selectedList.concat({
          ...defaultUserAddressParameters,
          address: lastAddress,
          type,
        });
      }

      setSubmitting(true);
      const {organizationId} = store.getState().organization;

      await client.mutate({
        mutation: InviteUserByAddresses,
        fetchPolicy: 'no-cache',
        variables: {
          addresses,
          organizationId,
        },
      });

      if (coSigningInvitesFF)
        toast.info(
          DetailedToast({
            ToastIcon: CheckCircleIcon,
            title: `${addresses.length >= 1 ? `${addresses.length}` : '0'} invite requests sent`,
            subtitle:
              'Invited users will be reviewed by your organization admins. You will receive an email notification once the invitation is approved.',
          }),
          {
            closeButton: false,
          },
        );
      else toast.success('Invitations has been sent');

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.inviteSent,
        params: {
          count: addresses.length,
          organization_id: organizationId,
        },
      });

      if (onComplete) onComplete();
      if (closeModal) closeModal();
    } catch (error) {
      if (typeof error === 'string') {
        setErrorMessage(error);
      } else {
        setErrorMessage('Failed to send invitations, please check your internet connection and try again');
      }
      focusedAutoCompleteInput();
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <AutoCompleteWrapper>
        <Autocomplete
          multiple
          disabled={isSubmitting}
          forcePopupIcon={false}
          id="inviteColleagueAutoComplete"
          options={options}
          value={selectedList}
          onKeyPress={handleKeyPress}
          onChange={handleOnChange}
          onInputChange={onInputChange}
          open={false}
          disableClearable
          getOptionLabel={(option) => option.address}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option.address} component="a" {...getTagProps({index})} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              rows={3}
              color={errorMessage ? 'secondary' : 'primary'}
              variant="outlined"
              placeholder={selectedList.length === 0 ? 'xxx@acme.com, abc@acme.com' : ''}
            />
          )}
        />
      </AutoCompleteWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {coSigningInvitesFF && <Divider style={{marginTop: 16, marginLeft: -24, marginRight: -24}} />}
      <FlexContainer style={{margin: coSigningInvitesFF ? '16px 0 -8px 0' : undefined}}>
        <Button disableTouchRipple variant="outlined" disabled={isSubmitting} onClick={closeModal}>
          cancel
        </Button>
        <Button
          disableTouchRipple
          variant="contained"
          color="secondary"
          disabled={!(selectedList.length + currentInputValid) || isSubmitting || errorMessage !== ''}
          onClick={handleSubmit}
        >
          {isSubmitting
            ? 'Sending...'
            : coSigningInvitesFF
            ? `Request invites (${selectedList.length + currentInputValid})`
            : `Invite ${selectedList.length >= 1 ? `${selectedList.length}` : ''} Colleagues`}
        </Button>
      </FlexContainer>
    </React.Fragment>
  );
};

export default AddressAutoCompleteManager;
