import React from 'react';

const DoubleCurlyBracesRegex = /(\{\{.*?\}\})/g;
const CurlyBraceWordPattern = /\{\{(\w+)\}\}/;

interface Parameter {
  key: string;
  type: 'datetime' | 'url';
  value: string | number;
}

export const formatMessage = (message: string, parameters: Parameter[]): React.ReactNode => {
  return message?.split(DoubleCurlyBracesRegex).map((part, index) => {
    const match = part.match(CurlyBraceWordPattern);
    if (match) {
      const key = match[1];
      const param = parameters.find((p) => p.key === key);
      if (param) {
        switch (param.type) {
          case 'datetime':
            return <strong key={index}>{typeof param.value === 'number' && formatDate(param.value)}</strong>;
          case 'url':
            const [linkText, url] = typeof param.value === 'string' ? param.value.split('|') : ['', ''];
            return (
              <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
            );
          default:
            return <span key={index}>{param.value}</span>;
        }
      }
    }
    return part;
  });
};

const formatDate = (epochTime: number): string => {
  const date = new Date(epochTime * 1000);
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZoneName: 'short',
  };
  const formattedDate = date.toLocaleString(undefined, options);
  return formattedDate;
};
