import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import * as ReactModal from 'react-modal';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import {WorkStatus, UserSelectOptionType, User} from 'src/types';
import StatusCheckList from 'src/pages/MessengerPage/messenger/change-status/ChangeStatusModalCheckList';
import {ON_SHIFT, BUSY, UNAVAILABLE} from 'src/constants/workStatus';
import UserSelectionDropdownContainer from 'src/pages/MessengerPage/messenger/change-status/ChangeStatusModalUserDropdownContainer';
import client from 'src/apollo';
import {lensPath, view} from 'ramda';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import ChangeUserStatusMutation from 'src/gql/mutation/ChangeUserStatusMutation';
import {toast} from 'react-toastify';
import fetchCurrentUserFragment from 'src/utils/fetchCurrentUserFragment';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {
  InputLabel,
  Typography,
  Select,
  CircularProgress,
  Button,
  InputAdornment,
  TextField,
  MenuItem,
  Grid,
} from '@mui/material';
import moment from 'moment';
import InfoIcon from 'src/svgs/InfoIcon';
import StyledTooltip from 'src/components/StyledTooltip';
import {CancelStatusSettingsDialog, ConfirmStatusSettingsDialog} from 'src/components/StatusSettingsDialog';
import Check from 'src/svgs/Check';
import {
  CHANGE_STATUS_MODAL_INVALID_CUSTOM_TIME_ERROR,
  STATUS_PLACEHOLDER,
  STATUS_TEXT_FIELD_LABEL,
} from 'src/constants/strings';
import ChangeStatusModalUserDropdownV2 from './ChangeStatusModalUserDropdownV2';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {DatePicker, LocalizationProvider, TimePicker} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';

const PREFIX = 'ChangeStatusModal';

const classes = {
  reverStatusDropdown: `${PREFIX}-reverStatusDropdown`,
};

const StatusDot = styled.div<{status: string | null}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: red;
  ${(props) => (props.status === ON_SHIFT ? `background: ${props.theme.colors.appleGreen}` : null)}
  ${(props) => (props.status === BUSY ? `background: ${props.theme.colors.watermelon}` : null)}
  ${(props) => (props.status === UNAVAILABLE ? `background: ${props.theme.colors.greyishBrown}` : null)}
`;

const OverridenStyledModal = styled(StyledModal)`
  z-index: 99;
  .ReactModal__Content {
    max-height: 90vh;
    padding: 24px;
  }
`;

const StyledOverridenStyledModal = styled(OverridenStyledModal)({
  [`& .${classes.reverStatusDropdown}`]: {
    width: '100%',
  },
});

const ModalWrapper = styled.div`
  overflow: hidden;
  overflow-y: unset !important;
  padding-right: 12px;
  margin-right: -16px;
`;

const AlternateContactSelectWrapper = styled.div`
  margin-top: 24px;
  .MuiPaper-root {
    max-width: 100% !important;
  }
`;

const StyledButton = styled(Button)`
  margin: auto !important;
  background-color: ${(props) => props.theme.colors.watermelon} !important;
  &:disabled {
    color: white !important;
    background-color: ${(props) => props.theme.colors.disabled} !important;
  }
`;

const Body = styled.div`
  margin: 16px 0;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: darkgrey;
  margin-right: 1em;
`;

const ChangeStatusSubHeading = styled(Typography)`
  color: ${(props) => props.theme.colors.greyishBrown} !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
`;

const FieldInputLabel = styled(InputLabel)`
  color: ${(props) => props.theme.colors.greyishBrown} !important;
  margin-bottom: 5px;
  font-size: 14px !important;
`;

const RevertInputLabel = styled(FieldInputLabel)`
  margin-top: 10px;
  white-space: normal;
`;

const ToastWrapper = styled.div`
  display: flex;
`;

const ToastIconWrapper = styled.div`
  width: 10vh;
`;

const InfoIconWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-left: 5px;
`;

interface Props {
  workStatusProps: WorkStatus | null;
  closeModal: () => void;
  reactModalProps: ReactModal.Props;
}

const revertTimeSelectOptionsArray = ['30 Minutes', '1 Hour', '4 Hours', '8 Hours', '16 Hours', 'Custom'];

const ToastMessageArray = {};
ToastMessageArray[BUSY] =
  'You will receive notifications for Urgent and STAT messages. You can update your status in Settings or continue using the app.';
ToastMessageArray[ON_SHIFT] =
  'You will now receive all notifications. You can update your status settings in Settings or continue using the app.';
ToastMessageArray[UNAVAILABLE] =
  'You will not receive any notifications, and will not be able to send or read messages until status is turned back to available or busy';

const ChangeStatusModal = ({workStatusProps, closeModal, reactModalProps}: Props) => {
  const [workStatus, setWorkStatus] = useState<WorkStatus | null>(null);
  const [statusDescription, setStatusDescription] = useState('');
  const [workStatusProxyUser, setWorkStatusProxyUser] = useState<UserSelectOptionType | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [revertDuration, setRevertDuration] = useState('30 Minutes');
  const [customRevertDate, setCustomRevertDate] = useState(new Date());
  const [customRevertTime, setCustomRevertTime] = useState(new Date());
  const [cancelStatusDialog, setCancelStatusDialog] = useState(false);
  const [confirmStatusDialog, setConfirmStatusDialog] = useState(false);
  const [isDateInvalid, setIsDateInvalid] = useState(true);
  const MAX_INPUT = 250;

  useEffect(() => {
    const currentUserFragment = fetchCurrentUserFragment();
    const {workStatusProxy, statusDescription, workStatus, statusExpiryDate} = currentUserFragment;

    if (workStatusProxy) {
      setWorkStatusProxyUser({
        label: `${workStatusProxy?.firstname} ${workStatusProxy?.lastname} `,
        value: workStatusProxy?.id,
        username: workStatusProxy?.username,
      });
    }
    setWorkStatus(workStatusProps || workStatus);
    setStatusDescription(statusDescription);
    setCustomRevertDate(Boolean(statusExpiryDate) ? statusExpiryDate : new Date());
    setCustomRevertTime(Boolean(statusExpiryDate) ? statusExpiryDate : new Date());
    setRevertDuration(
      Boolean(statusExpiryDate)
        ? revertTimeSelectOptionsArray[revertTimeSelectOptionsArray.length - 1]
        : revertTimeSelectOptionsArray[0],
    );

    // Added to prevent scroll in the background body to prevent alternate contact dropdown to not scroll.

    document.body.style.position = 'fixed';
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.statusChange);
  }, [workStatusProps]);

  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  if (paginatedContactsFeatureFlag === undefined) {
    return null;
  }

  const transformListValue = (status: WorkStatus | null) => {
    if (status === ON_SHIFT) return 'Available';
    if (status === BUSY) return 'Busy';
    if (status === UNAVAILABLE) return 'Unavailable';
    return '';
  };

  const saveStatusHandler = async (e) => {
    if (workStatus !== ON_SHIFT) setConfirmStatusDialog(true);
    else await onSubmit(e);
  };

  /**
   * Functional component to show in the toast
   * @param workStatus String constant to show in the status text
   */
  const ToastContent = (workStatus: WorkStatus | null) => {
    if (workStatus === null) return null;

    return (
      <ToastWrapper>
        <ToastIconWrapper>
          <Check strokefill={'#fff'} stroke={'#fff'} />
        </ToastIconWrapper>
        <div>
          <b>Your status is set to {transformListValue(workStatus)}</b>
          <Typography variant={'body2'}>{ToastMessageArray[workStatus]}</Typography>
        </div>
      </ToastWrapper>
    );
  };

  const enterKeyListener = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const modalElement = document.querySelector('.ReactModal__Content');
      const activeElement = document.activeElement;
      if (modalElement === activeElement) onSubmit(e);
    }
  };

  const handleTextFieldChange = (e) => {
    setStatusDescription(e.target.value);
  };

  const handleConfirmBtn = async (e, resetFields) => {
    handleConfirmStatusDialogKeepEditingBtn();
    await onSubmit(e, resetFields);
  };

  const handleCancelStatusDialogKeepEditingBtn = () => {
    setCancelStatusDialog(false);
  };

  const handleConfirmStatusDialogKeepEditingBtn = () => {
    setConfirmStatusDialog(false);
  };

  const handleRevertStatusDurationChange = (e) => {
    setRevertDuration(e.target.value);
  };

  const handleDateChange = (e) => {
    setIsDateInvalid(e?._isValid);
    setCustomRevertDate(e);
  };

  const handleTimeChange = (e) => {
    setCustomRevertTime(e);
  };

  /**
   * Function returns time string to display in UI when the status will be set back to available.
   * Calculates based on time select in revert dropdown else if custom is selected return from custom date time
   * @param unformatted {boolean} - to return the revert time as formatted string or as moment object
   */
  const turnBackAvailableTime = (unformatted = false) => {
    let revertTime;
    if (revertDuration !== revertTimeSelectOptionsArray[revertTimeSelectOptionsArray.length - 1]) {
      // @ts-ignore
      revertTime = moment().add(revertDuration.split(' ')[0], revertDuration.split(' ')[1].toLowerCase().charAt(0));
      return unformatted ? revertTime : revertTime.format('MMM, DD HH:mm');
    } else {
      revertTime = moment(
        moment(customRevertDate).format('YYYY-MM-DD') + ' ' + moment(customRevertTime).format('HH:mm:ss'),
      );
      return unformatted
        ? revertTime
        : `${moment(customRevertDate).format('MMM, DD')} ${moment(customRevertTime).format('HH:mm')} `;
    }
  };

  const onClickColleague = (selectedValue: {data: UserSelectOptionType}) => {
    setWorkStatusProxyUser(selectedValue?.data ?? null);
  };

  const onSubmit = async (e, resetFields = []) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const currentUserFragment: User = fetchCurrentUserFragment();
      const revertTime = turnBackAvailableTime(true);

      const isCustomTimeInPast = moment(revertTime).isBefore();

      if (isCustomTimeInPast) {
        toast.error(CHANGE_STATUS_MODAL_INVALID_CUSTOM_TIME_ERROR, {
          className: 'Toast-Container',
        });
        return;
      }

      const {
        workStatus: cachedWorkStatus,
        statusDescription: cachedstatusDescription,
        workStatusProxy: cachedWorkStatusProxy,
        statusExpiryDate: cachedStatusExpiryDate,
      } = currentUserFragment;
      const proxyUserId = workStatusProxyUser?.value ?? null;

      if (
        workStatus === cachedWorkStatus &&
        statusDescription === cachedstatusDescription &&
        proxyUserId === (cachedWorkStatusProxy ? cachedWorkStatusProxy.id : null) &&
        moment(cachedStatusExpiryDate).diff(moment(revertTime), 'minutes') === 720
      ) {
        closeModal();
        return;
      }

      let variablesObject = {
        newStatus: workStatus,
        statusDescription,
        proxyId: proxyUserId,
        resetFields,
      };

      if (workStatus !== ON_SHIFT)
        Object.assign(variablesObject, {startDate: moment.utc().format(), endDate: moment.utc(revertTime).format()});

      setLoading(true);
      await client.mutate({
        mutation: ChangeUserStatusMutation,
        variables: variablesObject,
        update: (store, {data}) => {
          const idLensPath = lensPath([`user`, 'status', 'id']);
          const userId = view(idLensPath, data);

          store.writeFragment({
            id: `User:${userId} `,
            fragment: GeneralUserFragment,
            fragmentName: 'GeneralUserFragment',
            data: {
              ...currentUserFragment,
              workStatus,
              statusDescription,
              workStatusProxy: proxyUserId
                ? {
                    id: proxyUserId,
                    __typename: 'ChatMember',
                  }
                : null,
            },
          });
        },
      });
      setLoading(false);
      closeModal();
      toast(ToastContent(workStatus), {
        className: 'Toastify__toast--success',
        bodyClassName: 'Toastify__toast--success',
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.changeStatus,
        params: {
          status: workStatus,
          alternate_contact_user_id: proxyUserId,
          source: window.location.href,
          expire_time: null,
        },
      });

      window.location.reload();
    } catch {
      toast.error('Failed to change status, please try again');
      setLoading(false);
    }
  };

  const {isOpen} = reactModalProps;

  const isRevertTimeCustom = revertDuration === revertTimeSelectOptionsArray[revertTimeSelectOptionsArray.length - 1];

  return (
    <StyledOverridenStyledModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <Header>
        <span>Status settings</span>
        <CloseBtn onClick={closeModal} />
      </Header>
      <ChangeStatusSubHeading variant="body2" gutterBottom={true}>
        Your status will be set to <b>{transformListValue(workStatus)}</b>. Other people will see this status message
        when they send you a message.
      </ChangeStatusSubHeading>
      <ModalWrapper>
        <form onSubmit={onSubmit}>
          <Body>
            <FieldInputLabel htmlFor="statusDescription">{STATUS_TEXT_FIELD_LABEL}</FieldInputLabel>
            <TextField
              id="statusDescription"
              variant="outlined"
              multiline={true}
              fullWidth
              rows={2}
              size={'small'}
              value={statusDescription ? statusDescription : ''}
              onChange={handleTextFieldChange}
              helperText={`${statusDescription?.length ?? 0} /${MAX_INPUT}`}
              color={statusDescription?.length > MAX_INPUT ? 'secondary' : 'primary'}
              placeholder={STATUS_PLACEHOLDER}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StatusDot status={workStatus} />
                  </InputAdornment>
                ),
              }}
            />
            <StatusCheckList setWorkStatus={setWorkStatus} workStatus={workStatusProps || workStatus} />

            {workStatus !== ON_SHIFT && (
              <React.Fragment>
                <RevertInputLabel htmlFor="revertStatusDdn">
                  Status automatically reverts back to available after
                </RevertInputLabel>
                <Select
                  variant={'outlined'}
                  className={classes.reverStatusDropdown}
                  id={'revertStatusDdn'}
                  value={revertDuration}
                  onChange={handleRevertStatusDurationChange}
                >
                  {revertTimeSelectOptionsArray.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
                {isRevertTimeCustom && (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container justifyContent="space-between" direction={'row'} spacing={1} marginTop={1}>
                      <Grid item md={6} lg={6}>
                        <DatePicker
                          showToolbar={false}
                          mask="___ __, ____"
                          inputFormat="MMM DD, yyyy"
                          disablePast={true}
                          // id="date-picker-inline"
                          value={customRevertDate}
                          onChange={handleDateChange}
                          renderInput={(props) => <TextField {...props} />}
                          // refuse={/[^a-zA-Z0-9]+/gi}
                          // KeyboardButtonProps={{
                          //   'aria-label': 'change date',
                          // }}
                        />
                      </Grid>
                      <Grid item md={6} lg={6}>
                        <TimePicker
                          // id="time-picker"
                          ampm={false}
                          value={customRevertTime}
                          onChange={handleTimeChange}
                          renderInput={(props) => <TextField {...props} />}
                          // KeyboardButtonProps={{
                          //   'aria-label': 'change time',
                          // }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                )}
                <RevertInputLabel>
                  You will not receive notifications for{' '}
                  {workStatus === BUSY ? 'general messages ' : 'any messages, including STAT & Urgent messages '}
                  {!isRevertTimeCustom && <b>for {revertDuration}</b>}. Your notifications will{' '}
                  <b>turn back on {turnBackAvailableTime()}</b>.
                </RevertInputLabel>
              </React.Fragment>
            )}
            <AlternateContactSelectWrapper>
              <Grid container direction={'row'}>
                <Typography variant={'h6'}>Alternate Contact</Typography>
                <StyledTooltip
                  arrow
                  className="styledPopper"
                  title={'Users will be prompted to message your alternate contact on the chat screen.'}
                >
                  <InfoIconWrapper>
                    <InfoIcon />
                  </InfoIconWrapper>
                </StyledTooltip>
              </Grid>
              {!paginatedContactsFeatureFlag ? (
                <UserSelectionDropdownContainer
                  workStatusProxyUser={workStatusProxyUser}
                  onClickColleague={onClickColleague}
                />
              ) : (
                <ChangeStatusModalUserDropdownV2
                  workStatusProxyUser={workStatusProxyUser}
                  onClickColleague={onClickColleague}
                />
              )}
            </AlternateContactSelectWrapper>
          </Body>
        </form>
      </ModalWrapper>
      <Grid container alignItems={'flex-end'} justifyContent={'flex-end'} spacing={1}>
        <Grid item>
          <Button variant="outlined" onClick={() => setCancelStatusDialog(true)}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <StyledButton
            disabled={isLoading || !isDateInvalid || statusDescription?.length > MAX_INPUT}
            variant="contained"
            color="secondary"
            onClick={(e) => saveStatusHandler(e)}
          >
            {isLoading && <StyledCircularProgress color="inherit" size={20} />}
            {isLoading ? 'Sending Request...' : 'Save'}
          </StyledButton>
        </Grid>
      </Grid>
      <CancelStatusSettingsDialog
        cancelStatusDialog={cancelStatusDialog}
        keepEditingHandler={handleCancelStatusDialogKeepEditingBtn}
        closeStatusSettingsModal={closeModal}
      />

      <ConfirmStatusSettingsDialog
        confirmStatusDialog={confirmStatusDialog}
        keepEditingHandler={handleConfirmStatusDialogKeepEditingBtn}
        handleConfirmBtn={handleConfirmBtn}
      />
    </StyledOverridenStyledModal>
  );
};

export default ChangeStatusModal;
