import {createTheme, adaptV4Theme} from '@mui/material';
const watermelon = '#ff3e55';
const errorRed = '#F44336';
const greyishBrown = '#4a4a4a';
const black = '#2b2b2b';
const redPink = '#ef3954';
const priorityYellow = '#ffc705';
const statRed = '#CB3E00';
const borderColor = '#d1d1d1';
const lightGrey = '#f2f2f2';
const chatBlue = '#3569e4';
const chatTeal = '#00859A';
const chatGrey = '#e6e7ec';
const warmGrey = '#9b9b9b';
const chatIconGrey = '#cac9c9';
const chatTextTitle = '#262626';
const slightlyGrey = '#c7c7cd';
const placeholder = '#adadad';
const appleGreen = '#7ed321';
const frogGreen = '#3bcc0b';
const androidGreen = '#009689';
const blackFontColor = '#222222';
const messageActionIconGrey = '#8B8B8B';
const labelGrey = '#969696';
const disabled = '#fcd6db';
const disabledLight = '#DCDCDC';
const borderBottomLight = '#d8d8d8';
const popperBackground = 'rgba(38, 39, 40, 0.9)';
const primary = '#00859A';
const ivory = '#fff4cd';
const lightYellow = '#FFEE33';
const messageBlack = '#222';
const messageWhite = '#fff';
const etherealMoodBlue = '#CCE7EB';
const paleBlue = '#DCE1E6';
const silvergrey = '#BCBCBC';
const ghostWhite = '#F6F6F9';
const textLightTertiary = '#767676';
const primaryText = '#000000de';
const backgroundColor = '#f8fafc';

export const colors = {
  main: watermelon,
  secondary: redPink,
  text: greyishBrown,
  labelGrey,
  ivory,
  disabled,
  disabledLight,
  primary,
  warmGrey,
  borderColor,
  watermelon,
  greyishBrown,
  redPink,
  black,
  priorityYellow,
  lightYellow,
  lightGrey,
  chatBlue,
  chatTeal,
  chatGrey,
  iconGrey: warmGrey,
  chatTextTitle,
  chatIconGrey,
  slightlyGrey,
  placeholder,
  appleGreen,
  androidGreen,
  frogGreen,
  errorRed,
  blackFontColor,
  messageActionIconGrey,
  borderBottomLight,
  popperBackground,
  statRed,
  messageBlack,
  messageWhite,
  etherealMoodBlue,
  paleBlue,
  silvergrey,
  ghostWhite,
  textLightTertiary,
  primaryText,
  backgroundColor,
} as const;

export const muiTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {main: '#00859A'},
      secondary: {main: '#ef3954'},
      info: {main: colors.primaryText},
    },
    colors,
    typography: {
      fontFamily: '"Open Sans", sans serif',
    },
    overrides: {
      MuiButton: {
        outlinedPrimary: {
          borderColor: 'rgba(0,0,0,0.23) !important',
        },
      },
    },
  }),
);
// mui themes pulled from pre-existing usages
// const signupTheme = createTheme({
//   palette: {
//     primary: { main: AppTheme.chatTeal },
//     secondary: { main: AppTheme.redPink },
//   },
// });
//
// const stepperTheme = createTheme({
//   palette: {
//     primary: { main: appTheme.main },
//     // secondary: { main: appTheme.chatBlue },
//   },
// });
// const escalationTheme = createTheme({
//   palette: {
//     primary: { main: AppTheme.chatTeal },
//     secondary: { main: AppTheme.redPink },
//   },
// });
//
// const escalationTheme = createTheme({
//   palette: {
//     primary: { main: AppTheme.chatTeal },
//     secondary: { main: AppTheme.redPink },
//   },
// });
//
// const organizationInviteTheme = createTheme({
//   palette: {
//     primary: { main: appTheme.androidGreen },
//     secondary: { main: appTheme.redPink },
//   },
// });
// const modalFormTheme = createTheme({
//   palette: {
//     primary: { main: appTheme.chatTeal },
//     secondary: { main: appTheme.main },
//   },
// });
// const stepperTheme = createTheme({
//   palette: {
//     primary: { main: appTheme.main },
//     // secondary: { main: appTheme.chatBlue },
//   },
// });
// const messageTemplateTheme = createTheme({
//   palette: {
//     primary: { main: Theme.chatTeal },
//     secondary: { main: Theme.watermelon },
//   },
//   typography: {
//     fontFamily: 'Open Sans',
//   },
// });
// const schedulingPageTheme = createTheme({
//   palette: {
//     primary: { main: appTheme.chatTeal },
//     secondary: { main: appTheme.main },
//   },
// });
// const invitePageTheme = createTheme({
//   palette: {
//     primary: { main: appTheme.chatTeal },
//     secondary: { main: appTheme.redPink },
//   },
//   overrides: {
//     MuiTooltip: {
//       tooltip: {
//         padding: '14px 18px',
//         fontSize: '14px',
//         color: 'white',
//         fontFamily: 'Nunito',
//       },
//     },
//     MuiChip: {
//       root: {
//         borderRadius: '4px',
//       },
//     },
//   },
// });
