import React from 'react';
import styled from '@emotion/styled';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {formatMessage} from './DynamicMaintenancePageUtils';
import {PrimaryButton} from 'src/styles/styled-components/StyledMaterialComponents';
import {HCBodyTwo, HCHeadingThree} from '../HypercareComponents';
import {muiTheme} from 'src/styles/theme';

const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: ${(props) => props.theme.colors.backgroundColor};
`;

const MaintenanceBox = styled.div`
  width: 90%;
  max-width: 600px;
  height: 426px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ImageStyle = styled.img`
  width: 257px;
  height: 194px;
  flex-shrink: 0;
  padding-bottom: 8px;
`;

const DynamicMaintenancePage: React.FC = () => {
  const {dynamicMaintenancePage} = useFlags();

  const handleButtonClick = () => {
    window.open(dynamicMaintenancePage?.learnMoreUrl, '_blank', 'noreferrer');
  };

  return (
    <MaintenanceContainer>
      <MaintenanceBox>
        <ImageStyle src={dynamicMaintenancePage?.image} alt="maintenance" />
        <HCHeadingThree color={muiTheme.colors.black}>{dynamicMaintenancePage?.title}</HCHeadingThree>
        <HCBodyTwo color={muiTheme.colors.greyishBrown} lineHeight="21px">
          {formatMessage(dynamicMaintenancePage?.body, dynamicMaintenancePage?.parameters)}
        </HCBodyTwo>
        {dynamicMaintenancePage?.LearnMoreText && (
          <PrimaryButton onClick={handleButtonClick}>{dynamicMaintenancePage?.LearnMoreText}</PrimaryButton>
        )}
      </MaintenanceBox>
    </MaintenanceContainer>
  );
};

export default DynamicMaintenancePage;
