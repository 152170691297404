import * as React from 'react';
import SyncIcon from 'src/svgs/SyncIcon';
import styled from '@emotion/styled';
import {StyledTooltipWhite} from 'src/components/StyledTooltip';
import {SYNCED_INFORMATION} from 'src/constants/strings';

const FormFieldLabelIconWrapper = styled.span<FormFieldSyncWrapperProps>`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 19px;
  border: 1px solid #d8d8d8;
  background: #f2f2f2;
  transform: ${(p) => (p.size === 'sm' ? 'scale(0.75)' : '')};
`;

type FormFieldSyncWrapperProps = {
  size?: 'default' | 'sm';
};

export const FormFieldSyncWrapper = ({size = 'default'}: FormFieldSyncWrapperProps) => {
  return (
    <StyledTooltipWhite title={SYNCED_INFORMATION} className="styledPopper" placement="bottom">
      <FormFieldLabelIconWrapper size={size}>
        <SyncIcon />
      </FormFieldLabelIconWrapper>
    </StyledTooltipWhite>
  );
};
