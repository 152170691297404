// @flow
import * as React from 'react';
import {FormFieldSyncWrapper} from 'src/components/@hc-ui/components/FormField/FormFieldInput/FormFieldSyncWrapper';
import styled from '@emotion/styled';

type ProfileInformationLabelWrapperProps = {
  labelText: string;
  isDirectorySynced: boolean;
};

const ProfileLabelWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
export const ProfileInformationLabelWrapper = ({labelText, isDirectorySynced}: ProfileInformationLabelWrapperProps) => {
  return (
    <ProfileLabelWrapper>
      <span>{labelText} </span>
      {isDirectorySynced ? <FormFieldSyncWrapper /> : null}
    </ProfileLabelWrapper>
  );
};
