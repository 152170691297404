import React from 'react';
import styled from '@emotion/styled';
import {HCHeadingThree, HCLabelOne} from '../HypercareComponents';
import {useFlags} from 'launchdarkly-react-client-sdk';

interface Props {
  title: string;
  description: React.ReactNode;
}

const BannerContainer = styled.div<{$backgroundColor?: string}>`
  background-color: ${(props) => props.$backgroundColor || 'red'};
  padding: 20px;
  text-align: center;
`;

const LoginDisabledBanner = ({title, description}: Props) => {
  const {dynamicMaintenanceLoginSignupBanner} = useFlags();

  return (
    <BannerContainer $backgroundColor={dynamicMaintenanceLoginSignupBanner?.login?.styles?.backgroundColor}>
      <HCHeadingThree
        color={dynamicMaintenanceLoginSignupBanner?.login?.styles?.textColor}
        fontSize={dynamicMaintenanceLoginSignupBanner?.login?.styles?.titleFontSize}
      >
        {title}
      </HCHeadingThree>
      <HCLabelOne
        color={dynamicMaintenanceLoginSignupBanner?.login?.styles?.textColor}
        fontSize={dynamicMaintenanceLoginSignupBanner?.login?.styles?.descriptionFontSize}
      >
        {description}
      </HCLabelOne>
    </BannerContainer>
  );
};

export default LoginDisabledBanner;
