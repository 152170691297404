import React from 'react';
import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import AlertTriangleIcon from 'src/svgs/AlertTriangleIcon';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';

const GreetingText = styled.p`
  margin-bottom: -12px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

const WarningWrapper = styled.div<{coSigningInvitesFF: boolean}>`
  margin: 0.5em 0;
  display: flex;
  padding: 1em;
  opacity: 0.8;
  border-radius: 3px;
  background-color: ${(props) => (props.coSigningInvitesFF ? props.theme.colors.lightGrey : props.theme.colors.ivory)};
`;

const WarningIconWrapper = styled.div`
  margin-right: 1em;
  padding-top: 2px;
  svg {
    color: ${(props) => props.theme.colors.priorityYellow};
  }
`;

const WarningDetail = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

interface Props {
  currentTab: number;
}

const InviteColleagueModalContent = ({currentTab}: Props) => {
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  return (
    <React.Fragment>
      {currentTab === 0 && (
        <React.Fragment>
          <WarningWrapper coSigningInvitesFF={!!coSigningInvitesFF}>
            <WarningIconWrapper>
              {coSigningInvitesFF ? <InfoIcon style={{fill: 'grey'}} /> : <AlertTriangleIcon />}
            </WarningIconWrapper>
            <WarningDetail>
              {coSigningInvitesFF
                ? 'Per organizational policy, your invites will be reviewed by administrators prior to being sent out to invitees. If you’re an Admin, invitation will be automatically approved.'
                : 'Please only invite colleagues that are part of your organization. They will receive the instructions and an organization code to join Hypercare and your organization.'}
            </WarningDetail>
          </WarningWrapper>
          <GreetingText>Enter email addresses separated by a comma</GreetingText>
        </React.Fragment>
      )}
      {/*TODO: remove with Co-Sign Invites Feature Flag removal.*/}
      {currentTab === 1 && (
        <React.Fragment>
          <WarningWrapper coSigningInvitesFF={!!coSigningInvitesFF}>
            <WarningIconWrapper>
              <AlertTriangleIcon />
            </WarningIconWrapper>
            <WarningDetail>
              This is a unique access code that allows users to join your organization. Please only share it with
              colleagues that are part of your organization.
            </WarningDetail>
          </WarningWrapper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default InviteColleagueModalContent;
