// local
export const AUTH_INFO = 'authInfo';
export const HYPERCARE_USERNAME = 'hypercareUsername';
export const HYPERCARE_REGION = 'hypercareRegion';
export const SSO_VENDOR = 'ssoVendor';
export const ORG_URL = 'orgUrl';
export const CURRENT_ORGANIZATION = 'currentOrganization';
export const INTRO_DONE = 'intro-done';
export const ORGANIZATION_ACCOUNTS_DATA = 'organizationAccountsData';
export const isBannerExpanded = 'isBannerExpanded';

// session
export const IS_EXPIRED = 'isExpired';
export const PREVIOUS_ORGANIZATION = 'previousOrganization';
export const IS_JOIN_ORG_FROM_ONBOARDING = 'isJoinOrgFromOnboarding';
export const IS_FINISHING_CREATE_ORGANIZATION = 'isFinishingCreateOrganization';
export const DESIRED_ROUTE = 'desiredRoute';
export const SESSION_ID = 'sessionID';

// debug
export const DEBUG = 'debug';
